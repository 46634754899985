import styled from 'styled-components'
import { useRoute, useLocation } from 'wouter'
import { Row, Button } from 'antd'
import { LogoutOutlined } from '@ant-design/icons'
import Connectivity from './Connectivity'
import LegalInfos from './LegalInfos'
import Logo from './img/Logo.svg'
import { useTranslation } from 'react-i18next'
import useAuth from '../../../hooks/useAuth'

const VoeFooter = styled.footer`
  background: ${({ theme }) => theme.primaryColor};
  padding: 30px;
`

const FooterRow = styled(Row)`
  margin-bottom: 20px;
`

const LegalInfoLink = styled.a`
  color: #fff;
  text-decoration: underline;
  &:active, &:hover, &:focus, &:visited {
    color: #fff;
    text-decoration: underline;
  }
`

const ScalingLogo = styled.img`
  height: auto;
  width: 30vw;
  max-width: 130px;
  max-height: 30px;
`

const LogoutButton = styled(Button)`
  color: #fff;
  &:hover {
    color: #ddd;
  }
  height: 40px;
  margin-bottom: 16px;
  text-align: left;
  border-top: 1px solid #4D71A3;
  border-bottom: 1px solid #4D71A3;
`

const HOMEPAGE_URL = 'https://voteos.com'
const Footer = (props) => {
  const [matchRedeem] = useRoute('/:address/redeem')
  const [matchConfirm] = useRoute('/:address/:id/confirm')
  const [, setLocation] = useLocation()
  const { t } = useTranslation()
  const { signedIn, logout } = useAuth()

  const handleLogout = () => {
    logout()
    if (matchRedeem || matchConfirm) {
      setLocation('/')
    }
  }

  return (
    <VoeFooter {...props}>
      <FooterRow>
        <a href={HOMEPAGE_URL} target='_blank' rel='noreferrer'>
          <ScalingLogo alt='Logo' src={Logo} />
        </a>
      </FooterRow>
      <FooterRow>
        <Connectivity />
      </FooterRow>
      {signedIn && <LogoutButton block type='text' onClick={handleLogout} icon={<LogoutOutlined />}>{t('logout')}</LogoutButton>}
      <FooterRow>
        <LegalInfoLink href='/legal' target='blank' rel='noopener noreferrer'>{t('footer.legal')}</LegalInfoLink>
      </FooterRow>
      <LegalInfos />
    </VoeFooter>
  )
}

export default Footer
