import { useState } from 'react'
import styled from 'styled-components'
import { Button, Row, Col, Radio, Typography } from 'antd'
import { FileDoneOutlined } from '@ant-design/icons'
import ErrorMessage from '../../../../common/ErrorMessage'
import Loading from './Loading'
import View from './View'
import MoodImage from './MoodImage'
import { useElection } from '@hpm/voteos-hooks'
import { useTranslation } from 'react-i18next'

const { Text } = Typography

const VoteButton = styled(Button)`
  height: auto;
  white-space: normal;
  font-size: 18px;
  border-radius: 5px;
  background: ${({ theme }) => theme.primaryColor} !important;
  margin-top: 10px;
  ${({ disabled }) => disabled ? 'opacity: 0.4;' : ''}
  ${({ disabled }) => disabled ? 'color: #ffffffbb !important;' : ''}
`

const AnswerRadio = styled(Radio)`
  white-space: normal;
  height: auto;
  width: 100%;
  font-size: 17px;
  padding-bottom: 8px;
  padding-top: 8px;
`

const ChooseableAnswers = ({ pollId, answers, allowAbstention, minVotes, maxVotes }) => {
  const [chosenAnswer, setChosenAnswer] = useState()
  const [error, setError] = useState()
  const { voteFor, abstentionAllFor, loading } = useElection()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { t } = useTranslation()

  const handleVote = async () => {
    setError()
    setIsSubmitting(true)
    try {
      if (chosenAnswer === 'abstain') {
        await abstentionAllFor({ pollId })
      } else {
        await voteFor({ pollId: pollId, answerIds: [chosenAnswer] })
      }
    } catch (error) {
      console.error(`Voting error: ${error.message}`)
      setError(error.message)
    }
    setIsSubmitting(false)
  }
  const areVoteCountsEqual = minVotes === maxVotes
  return (
    <Row gutter={[16, 8]}>
      {loading && isSubmitting && <Loading />}
      {loading && !isSubmitting && <View answers={answers} minVotes={minVotes} maxVotes={maxVotes} allowAbstention={allowAbstention} />}

      {!loading &&
        <>
          {answers?.map(({ id, title, image }) => {
            const isSelected = id === chosenAnswer
            return (
              <Col span={24} key={id}>
                <AnswerRadio
                  checked={isSelected}
                  onClick={() => setChosenAnswer(id)}
                >
                  <Row align='middle' wrap={false}>
                    {image && <Col flex='50px'><MoodImage src={image} /></Col>}
                    <Col flex='auto'>{title}</Col>
                  </Row>
                </AnswerRadio>
              </Col>
            )
          })}
          {allowAbstention && (
            <Col span={24}>
              <AnswerRadio
                checked={chosenAnswer === 'abstain'}
                onClick={() => setChosenAnswer('abstain')}
              >
                {t('election.poll.abstain')}
              </AnswerRadio>
            </Col>
          )}
          <Col span={24}>
            <Text type='secondary'>{areVoteCountsEqual ? t('election.poll.equalVotesDescription', { count: minVotes }) : t('election.poll.multipleChoiceInfo', { min: minVotes, max: maxVotes })}</Text>
          </Col>
          <Col span={24}>
            <VoteButton
              block
              disabled={!chosenAnswer}
              type='primary'
              icon={<FileDoneOutlined />}
              onClick={handleVote}
            >
              {t(chosenAnswer ? 'election.poll.vote' : 'election.poll.pleaseSelect')}
            </VoteButton>
          </Col>
          {!!error && (
            <Col span={24}>
              <ErrorMessage error={error} />
            </Col>
          )}
        </>}
    </Row>
  )
}

export default ChooseableAnswers
