import React from 'react'
import ReactMarkdown from 'markdown-to-jsx'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Link } from 'wouter'
import { ReactComponent as Logo } from '../Layout/Footer/img/Logo.svg'

const StyledLogo = styled(Logo)`
  margin-top: 3vh;
  margin-left: 2vw;
  path { fill: ${({ theme }) => theme.primaryColor}}; };
  cursor: pointer;
`

const WrapHeader = styled.div`
  background-color: white;
  width: 100%;
  position: fixed;
  top: 0;
`

const WrapBody = styled.div`
  padding-top: 4vh;
  padding-left: 2vw;
  padding-right: 2vw;
`

const styledHeader = styled.h1`
  font-size: 1.8em;
`

const Legal = () => {
  const { t } = useTranslation()

  return (
    <>
      <WrapHeader>
        <Link to='/'>
          <StyledLogo />
        </Link>
      </WrapHeader>
      <WrapBody>
        <ReactMarkdown options={{
          overrides: {
            h1: {
              component: styledHeader,
              props: {
                className: 'foo'
              }
            }
          }
        }}
        >
          {t('legal')}
        </ReactMarkdown>
      </WrapBody>
    </>
  )
}

export default Legal
