import { Row, Col, Typography } from 'antd'
const { Text } = Typography

const LabeledValue = ({ title, children }) => {
  return (
    <Row>
      <Col flex={1}>
        <Text>{title}:</Text>
      </Col>
      <Col flex={1} align='right'>
        {children}
      </Col>
    </Row>
  )
}

export default LabeledValue
