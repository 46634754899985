import { Typography, Row, Col } from 'antd'
import { PaperClipOutlined } from '@ant-design/icons'
import Answers from './Answers'
import styled from 'styled-components'

const { Paragraph, Link } = Typography

const Description = styled(Paragraph)`
  font-size: 16px;
`

const AttachmentLink = styled(Link)`
  > span {
    font-size: 14px;
    text-decoration: underline;
  }
`

const Poll = ({ id, metadata: { description, attachments, resultDisplayType }, ...restPoll }) => {
  return (
    <Row>
      {!!description && (
        <Col span={24}>
          <Description style={{ whiteSpace: 'break-spaces' }}>{description || '-'}</Description>
        </Col>
      )}
      {(attachments && !!attachments.length) && (

        <Col span={24}>
          <Paragraph>{attachments?.map(({ name, link }) => (
            <Col key={`${link}-${name}`} span={24}>
              <AttachmentLink href={link} target='_blank' rel='noreferrer'><PaperClipOutlined /><span>{name}</span></AttachmentLink>
            </Col>
          ))}
          </Paragraph>
        </Col>
      )}
      <Col span={24}>
        <Answers pollId={id} {...restPoll} resultDisplayType={resultDisplayType} />
      </Col>
    </Row>
  )
}

export default Poll
