import { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { Typography, Row, Col, Button, Collapse, Tag } from 'antd'
import { PaperClipOutlined, LockOutlined, UnlockOutlined, BarChartOutlined, HourglassOutlined, CheckOutlined } from '@ant-design/icons'
import NotFound from '../NotFound'
import NotPublic from './NotPublic'
import Logo from '../../common/Logo'
import Poll from './Poll'
import { useTranslation } from 'react-i18next'

const { Title, Paragraph } = Typography
const { Panel } = Collapse

const StyledCollapse = styled(Collapse)`
  background: #fff;
  border: 0;
`

const StyledPanel = styled(Panel)`
  margin-bottom: 15px;
  overflow: hidden;
  border: solid 0.5px ${({ theme }) => theme.primaryColor} !important;
  border-radius: 5px !important;
  color: ${({ theme }) => theme.primaryColor};
  > .ant-collapse-header {
    font-size: 18px;
    font-weight: 600;
    color: ${({ theme }) => theme.primaryColor} !important;
  }

  > .ant-collapse-content {
    border-top: solid 0.5px ${({ theme }) => theme.primaryColor};
  }
`

const QuestionNumber = styled.div`
  color: ${({ theme }) => theme.primaryColor} !important;
  font-size: 18px !important;
  font-weight: 600;
`

const NoMarginTitle = styled(Title)`
  margin-bottom: 0px !important;
`

export default function ElectionView ({ state, title, description, logo, polls = [], totalVoteWeight, attachments = [], loading, isModerated }) {
  const { t } = useTranslation()

  const [selectedPollId, setSelectedPollId] = useState()
  const [openPolls, setOpenPolls] = useState([])

  const isOpened = state === 'opened'
  const hasEnded = state === 'ended'
  const hasStarted = state === 'started'

  useEffect(() => {
    if (!polls) return
    const activePoll = polls.find(({ active }) => !!active)
    setSelectedPollId(activePoll?.id)
  }, [polls])

  useEffect(() => {
    if (!polls?.length) { return }
    let currentPoll = selectedPollId
    // open first poll, when no poll is active but election has been opened or started
    if (!currentPoll) {
      currentPoll = (isOpened || hasStarted) && polls[0]?.id
    }

    let openPolls = [currentPoll]
    // open all polls when election has ended
    if (hasEnded || !isModerated) {
      openPolls = polls?.map(({ id }) => id)
    }
    setOpenPolls(openPolls)
  }, [polls, selectedPollId, hasEnded, hasStarted, isOpened, isModerated])

  useEffect(() => {
    // reset scroll from adding ballots
    window.scrollTo(0, 0)
  }, [])

  const getIconForPollState = useCallback(({ active, isAcceptingVotes, order, voted }) => {
    if (loading && !voted && active && isAcceptingVotes) {
      return <HourglassOutlined />
    }
    if (voted && !hasEnded && isAcceptingVotes) return <CheckOutlined />
    if (hasEnded || (voted && !isAcceptingVotes)) return <BarChartOutlined />
    if ((!isModerated || active) && isAcceptingVotes) return <UnlockOutlined />

    const currentlyActivePoll = polls.find(p => !!p.active)
    if (currentlyActivePoll && order < currentlyActivePoll.order) {
      return <BarChartOutlined />
    }
    return <LockOutlined />
  }, [polls, hasEnded, isModerated, loading])

  if (!state) {
    return <></>
  }

  if (state === 'created') {
    return <NotPublic />
  }
  if (state === 'removed') {
    return <NotFound />
  }

  return (
    <Row gutter={[16, 16]}>
      {!!logo && (
        <Col span={24} align='center'>
          <Logo src={logo} />
        </Col>
      )}

      <Col span={24}>
        <NoMarginTitle level={3}>{title}</NoMarginTitle>
      </Col>

      <Col span={24}>
        {state === 'started' &&
          <Tag color='green'>{t('election.started')}</Tag>}
        {(state === 'ended' || state === 'cancelled') &&
          <Tag color='red'>{t('election.ended')}</Tag>}
        {state === 'opened' &&
          <Tag color='warning'>{t('election.opened')}</Tag>}
      </Col>

      {!!description && (
        <Col span={24}>
          <Paragraph style={{ whiteSpace: 'break-spaces' }}>{description}</Paragraph>
        </Col>
      )}

      <Col span={24}>
        {attachments?.map(({ name, link }) => (
          <Button
            key={link}
            type='link'
            href={link}
            target='_blank'
            rel='noreferrer'
            icon={<PaperClipOutlined />}
          >
            {name}
          </Button>
        ))}
      </Col>

      <Col span={24}>
        <StyledCollapse
          accordion={!hasEnded && isModerated}
          activeKey={openPolls}
          onChange={pollId => setSelectedPollId(String(pollId))}
          expandIcon={({ position }) => <QuestionNumber>{position}.</QuestionNumber>}
        >
          {polls?.map((poll, index) => (
            <StyledPanel
              key={poll.id}
              header={poll.title}
              extra={getIconForPollState(poll)}
              position={index + 1}
            >
              <Poll
                key={poll.id}
                state={state}
                isModerated={isModerated}
                totalVoteWeight={totalVoteWeight}
                {...poll}
              />
            </StyledPanel>
          ))}

        </StyledCollapse>
      </Col>
    </Row>
  )
}
