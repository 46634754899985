import PackageInfo from '../../../../package.json'
import styled from 'styled-components'

const Text = styled.span`
  color: #fff;
`

const VersionText = (props) => {
  return (
    <Text type='secondary' {...props}>v{PackageInfo.version}</Text>
  )
}

export default VersionText
