import styled from 'styled-components'
import { Divider, Typography, Row, Col, Image } from 'antd'
import VerticalSpace from '../../../common/VerticalSpace'

import BallotImage from '../Ballot.png'
import { useTranslation } from 'react-i18next'

const { Text } = Typography

const Title = styled(Typography.Title)`
  margin-bottom: 0px !important;
`

const BallotRow = styled(Row)`
  padding-top: 16px;
  padding-bottom: 16px;
`
const BallotCount = ({ count = 0, weight, address, signedIn, redeeming, state }) => {
  const { t } = useTranslation()

  return (
    <BallotRow align='middle'>
      <Col span={18}>
        <VerticalSpace>
          <Text type='secondary'>{t('account.balance')}</Text>
          <Title level={5}>
            {count} {t('election.ballots', { count })}
          </Title>
          {weight > count && (
            <Text type='secondary'>
              {t('election.ballot.weight')}: {weight}
            </Text>
          )}
        </VerticalSpace>

      </Col>
      <Col span={6} align='right'>
        <Image width={100} src={BallotImage} preview={false} />
      </Col>
      <Divider />
    </BallotRow>
  )
}

export default BallotCount
