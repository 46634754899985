import { useState } from 'react'
import styled from 'styled-components'
import { Button, Row, Col, Checkbox, Radio, Typography } from 'antd'
import { FileDoneOutlined } from '@ant-design/icons'
import ErrorMessage from '../../../../common/ErrorMessage'
import Loading from './Loading'
import MoodImage from './MoodImage'
import View from './View'
import { useElection } from '@hpm/voteos-hooks'
import { useTranslation } from 'react-i18next'

const { Text } = Typography

const VoteButton = styled(Button)`
  height: auto;
  white-space: normal;
  font-size: 18px;
  border-radius: 5px;
  background: ${({ theme }) => theme.primaryColor} !important;
  margin-top: 10px;
  ${({ disabled }) => disabled ? 'opacity: 0.4;' : ''}
  ${({ disabled }) => disabled ? 'color: #ffffffbb !important;' : ''}
`

const AnswerRadio = styled(Radio)`
  white-space: normal;
  height: auto;
  width: 100%;
  font-size: 17px;
  padding-bottom: 8px;
  padding-top: 8px;
`

const AnswerCheckbox = styled(Checkbox)`
  white-space: normal;
  height: auto;
  width: 100%;
  font-size: 17px;
  padding-bottom: 8px;
  padding-top: 8px;
`

const ChooseableAnswers = ({ pollId, answers, minVotes, maxVotes, allowAbstention }) => {
  const [chosenAnswers, setChosenAnswers] = useState([])
  const [error, setError] = useState()
  const { voteFor, abstentionAllFor, loading } = useElection()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { t } = useTranslation()

  const handleVote = async () => {
    setError()
    setIsSubmitting(true)
    try {
      if (chosenAnswers[0] === 'abstain') {
        await abstentionAllFor({ pollId })
      } else {
        await voteFor({ pollId, answerIds: chosenAnswers })
      }
    } catch (error) {
      console.error(`Voting error: ${error.message}`)
      setError(error.message)
    }
    setIsSubmitting(false)
  }

  const handleCheckClick = (id) => {
    if (chosenAnswers[0] === 'abstain') {
      setChosenAnswers([id])
      return
    }
    if (chosenAnswers.includes(id)) {
      const newAnswers = chosenAnswers.filter((answerId) => {
        return id !== answerId
      })
      setChosenAnswers(newAnswers)
    } else {
      setChosenAnswers([...chosenAnswers, id])
    }
  }

  let disableVoteButton = false
  if (!chosenAnswers || !chosenAnswers.length || (!allowAbstention && chosenAnswers.length < minVotes) || (allowAbstention && !chosenAnswers.includes('abstain') && chosenAnswers.length < minVotes)) {
    disableVoteButton = true
  }

  const areVoteCountsEqual = minVotes === maxVotes
  return (
    <Row gutter={[16, 8]}>
      {loading && isSubmitting && <Loading />}
      {loading && !isSubmitting && <View answers={answers} minVotes={minVotes} maxVotes={maxVotes} allowAbstention={allowAbstention} allowMultiple />}
      {!loading &&
        <>
          {answers?.map(({ id, title, votes, image }) => {
            return (
              <Col span={24} key={id}>
                <AnswerCheckbox
                  checked={chosenAnswers.includes(id)}
                  onClick={() => handleCheckClick(id)}
                  disabled={!chosenAnswers.includes(id) && chosenAnswers.length === maxVotes}
                >
                  <Row align='middle' wrap={false}>
                    {image && <Col flex='50px'><MoodImage src={image} /></Col>}
                    <Col flex='auto'>{title}</Col>
                  </Row>
                </AnswerCheckbox>
              </Col>
            )
          })}
          {allowAbstention &&
            <Col span={24}>
              <AnswerRadio
                checked={chosenAnswers[0] === 'abstain'}
                onClick={() => setChosenAnswers(['abstain'])}
              >
                {t('election.poll.abstain')}
              </AnswerRadio>
            </Col>}
          <Col span={24}>
            <Text type='secondary'>{areVoteCountsEqual ? t('election.poll.equalVotesDescription', { count: minVotes }) : t('election.poll.multipleChoiceInfo', { min: minVotes, max: maxVotes })}</Text>
          </Col>

          <Col span={24}>
            <VoteButton
              block
              disabled={disableVoteButton}
              type='primary'
              icon={<FileDoneOutlined />}
              onClick={handleVote}
            >
              {t(chosenAnswers ? 'election.poll.vote' : 'election.poll.pleaseSelect')}
            </VoteButton>
          </Col>
          {!!error && (
            <Col span={24}>
              <ErrorMessage error={error} />
            </Col>
          )}
        </>}
    </Row>
  )
}

export default ChooseableAnswers
