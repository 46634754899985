import SingleChoice from './SingleChoice'
import View from './View'
import Result from './Result'
import MultipleChoice from './MultipleChoice'

const Answers = ({ pollId, answers, voted, active, isAcceptingVotes, hasVotes, state, allowMultiple, isModerated, ...restPoll }) => {
  if (!answers) {
    return (<></>)
  }

  if (['started', 'cancelled', 'ended'].includes(state) && voted && !isAcceptingVotes) {
    return <Result answers={answers} {...restPoll} />
  }

  if (['started', 'cancelled', 'ended'].includes(state) && (active || !isModerated) && isAcceptingVotes && !voted) {
    if (allowMultiple) {
      return <MultipleChoice pollId={pollId} answers={answers} {...restPoll} />
    }
    return <SingleChoice pollId={pollId} answers={answers} {...restPoll} />
  }
  return <View answers={answers} voted={voted} {...restPoll} allowMultiple={allowMultiple} />
}

export default Answers
