import { useState, useEffect, useCallback } from 'react'
import { message } from 'antd'
import View from './View'
import Loading from '../../common/Loading'
import Header from './Header'
import NotFound from '../NotFound'
import { useLocation } from 'wouter'
import { useTranslation } from 'react-i18next'
import useAuth from '../../../hooks/useAuth'
import { useElection } from '@hpm/voteos-hooks'
import { getWalletForPrivateKey } from '../../../hooks/useWallet'

export default function ElectionHome ({ params }) {
  const { loading: loadingAuth, signedIn } = useAuth()
  const { election, polls, setAddress, loading: loadingElection, getVoteTokenCountOf, voteTokenCount, error: electionError, subscribeToEvents, listVotes } = useElection()
  const { t } = useTranslation()
  const [, setLocation] = useLocation()
  const [totalVoteWeight, setTotalVoteWeight] = useState()

  const loadVoteWeight = useCallback(async () => {
    if (!election || totalVoteWeight) {
      return
    }
    const votes = await listVotes(0, election.voteCount)
    const totalWeight = votes.reduce((previous, current) => {
      return previous + (current.weight || 1)
    }, 0)
    setTotalVoteWeight(totalWeight)
  }, [listVotes, election, totalVoteWeight])

  useEffect(() => {
    loadVoteWeight()
    if (election && electionError) {
      message.error(t(electionError))
    }
  }, [election, electionError, t, loadVoteWeight])

  useEffect(() => {
    setAddress(params.address)
  }, [params.address, setAddress])

  useEffect(subscribeToEvents, [subscribeToEvents])

  useEffect(() => {
    const finishedLoading = !loadingAuth && !loadingElection

    if (finishedLoading && election?.address && signedIn && params?.privatekey && ['opened', 'started'].includes(election?.state)) {
      try {
        const wallet = getWalletForPrivateKey(params.privatekey)
        getVoteTokenCountOf(wallet.address)
          .then(count => {
            const isUnstartedModeratedElection = election.activePollId === '0' && !election.isAcceptingVotes
            const isModeratedElectionWithoutBallots = !election.isModerated && voteTokenCount === 0
            if (count > 0 && (isUnstartedModeratedElection || isModeratedElectionWithoutBallots)) {
              // redirect to confirm ballot redemption
              setLocation(`/${params.address}/${params.privatekey}/confirm`)
            }
          })
      } catch (err) {
        console.error(err)
      }
    }
  }, [loadingAuth, loadingElection, election, params, signedIn, getVoteTokenCountOf, setLocation, voteTokenCount])

  let Content = <Loading description={t('election.loadingHint')} />

  if (!election && electionError && !loadingAuth && !loadingElection) {
    Content = <NotFound />
  }
  if (election && !electionError) {
    Content = <View {...election} polls={polls} loading={loadingElection} totalVoteWeight={totalVoteWeight} />
  }

  const showHeader = ['opened', 'started', 'ended'].includes(election?.state)
  return (
    <>
      {showHeader && <Header />}
      {Content}
    </>
  )
}
