import styled from 'styled-components'
import { Row, Col, Radio, Image, Checkbox, Typography } from 'antd'
import MoodImage from '../MoodImage'
import VotedImage from './Voted.svg'
import { useTranslation } from 'react-i18next'

const { Text } = Typography
const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 25px; // perfectly align with loading animation
`

const AnswerRadio = styled(Radio)`
  white-space: normal;
  height: auto;
  width: 100%;
  font-size: 16px;
  padding-bottom: 8px;
  padding-top: 8px;
  align-items: center;
  > span:not(.ant-radio) {
    padding-top: 6px;
    width: 100%;
  }
`

const AnswerCheckbox = styled(Checkbox)`
  white-space: normal;
  height: auto;
  align-items: center;
  width: 100%;
  font-size: 17px;
  padding-bottom: 8px;
  padding-top: 8px;
  > span:not(.ant-checkbox) {
    padding-top: 8px;
  }
`

const ShowAnswers = ({ answers, voted, allowMultiple, allowAbstention, minVotes, maxVotes }) => {
  const { t } = useTranslation()
  if (voted) {
    return <CenterContainer><Image src={VotedImage} preview={false} /></CenterContainer>
  }
  const SelectionType = allowMultiple ? AnswerCheckbox : AnswerRadio
  const areVoteCountsEqual = minVotes === maxVotes
  return (
    <Row gutter={[16, 4]}>
      {answers.map(({ id, title, image }) => (
        <Col span={24} key={id}>
          <SelectionType disabled>
            <Row align='middle' wrap={false}>
              {image && <Col flex='50px'><MoodImage src={image} /></Col>}
              <Col flex='auto'>{title}</Col>
            </Row>
          </SelectionType>
        </Col>
      ))}
      {allowAbstention && <Col span={24}><AnswerRadio disabled>{t('election.poll.abstain')}</AnswerRadio></Col>}
      <Col span={24}>
        <Text disabled type='secondary'>{areVoteCountsEqual ? t('election.poll.equalVotesDescription', { count: minVotes }) : t('election.poll.multipleChoiceInfo', { min: minVotes, max: maxVotes })}</Text>
      </Col>
    </Row>
  )
}

export default ShowAnswers
