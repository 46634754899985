import { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { Link, useRoute } from 'wouter'
import { Row, Col, Button, Alert, Input } from 'antd'
import { LoginOutlined, FileAddOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import Ballots from './Count'
import useAuth from '../../../../hooks/useAuth'
import { useElection } from '@hpm/voteos-hooks'
import { useTranslation } from 'react-i18next'
import RoundedModal from '../../../common/RoundedModal'

const AuthButton = styled(Button)`
  border-radius: 5px;
  border: solid 1px ${({ theme }) => theme.primaryColor};
  span {
    color: ${({ theme }) => theme.primaryColor};
  }
`

const AddButton = styled(Button)`
  border-radius: 5px;
`

const CustomAlert = styled(Alert)`
  background-color: ${props => props.type === 'info' ? '#eff7ff' : ''};
`

export default function ElectionHeader ({ redeeming, ended }) {
  const [matchRedeem] = useRoute('/:address/redeem')
  const [matchConfirm] = useRoute('/:address/:id/confirm')
  const { loading: loadingAuth, signedIn, login, logout } = useAuth()
  const { election, loading: loadingElection, voteTokenCount, voteWeight, message, setMessage } = useElection()
  const { t } = useTranslation()
  const [showLoginModal, setShowLoginModal] = useState(false)
  const [loginEmail, setLoginEmail] = useState('')

  useEffect(() => {
    if (!loadingElection && !election) {
      setMessage()
      return
    }

    if (election && ['created', 'ended'].includes(election?.state)) {
      setMessage()
      return
    }

    if (!loadingAuth && !signedIn && election) {
      if (election?.state === 'opened' && !window.sessionStorage.getItem(`_initialMessageClosed_${election.address}`)) {
        setMessage({ type: 'info', text: t('election.login'), icon: <ExclamationCircleOutlined /> })
        return
      }
      if (election?.state === 'started' && !window.sessionStorage.getItem(`_initialMessageClosed_${election.address}_started`)) {
        setMessage({ type: 'warning', text: t('election.loginRunning'), icon: <ExclamationCircleOutlined /> })
      }
    }
  }, [loadingAuth, loadingElection, setMessage, signedIn, t, message?.text, election])

  const handleAuthButtonClick = useCallback(async () => {
    if (signedIn) {
      await logout()
    } else {
      setShowLoginModal(true)
    }
    setMessage()
  }, [signedIn, setMessage, logout])

  const handleLogin = async () => {
    if (loginEmail.includes('@')) {
      await login(loginEmail)
    }
  }
  const handleCloseMessage = useCallback(() => {
    if (message?.text === t('election.login')) {
      window.sessionStorage.setItem(`_initialMessageClosed_${election.address}`, true)
    }
    if (message?.text === t('election.loginRunning')) {
      window.sessionStorage.setItem(`_initialMessageClosed_${election.address}_started`, true)
    }
    setMessage()
  }, [setMessage, election, t, message?.text])

  const loginButton = <AuthButton icon={<LoginOutlined />} disabled={loadingAuth} loading={loadingAuth} onClick={handleAuthButtonClick}>{t('login')}</AuthButton>

  const addButton = ['opened', 'started'].includes(election?.state) && !election?.isAcceptingVotes && election?.activePollId === '0' && (
    <Link to={`/${election?.address}/redeem`}>
      <AddButton type='primary' icon={<FileAddOutlined />}>{t('election.ballot.add')}</AddButton>
    </Link>
  )

  return (
    <Row justify='end'>
      {!(matchRedeem || matchConfirm) && (
        <Col>
          {!signedIn ? loginButton : addButton}
        </Col>
      )}
      <Col span={24}>
        <Ballots
          count={voteTokenCount}
          weight={voteWeight}
          address={election ? election.address : ''}
          signedIn={signedIn}
          redeeming={redeeming}
          state={election?.state}
        />
      </Col>
      {message && (
        <Col span={24}>
          <Row gutter={[0, 16]}>
            <Col span={24}>
              <CustomAlert
                closable
                message={message.text}
                type={message.type}
                onClose={handleCloseMessage}
                icon={message.icon}
                showIcon
              />
            </Col>
            <Col span={24} />
          </Row>
        </Col>
      )}
      <RoundedModal
        title={t('login')}
        centered
        visible={showLoginModal}
        onOk={handleLogin}
        okText={t('login')}
        onCancel={() => setShowLoginModal(false)}
        disabled={!loginEmail || !loginEmail.includes('@')}
      >
        <Input
          placeholder={t('email')}
          size='large'
          onChange={(e) => { setLoginEmail(e?.target?.value) }}
          onPressEnter={handleLogin}
          status={(loginEmail && !loginEmail.includes('@')) ? 'error' : null}
        />
      </RoundedModal>
    </Row>
  )
}
