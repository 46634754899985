import { useTranslation } from 'react-i18next'
import { Typography } from 'antd'

const { Title, Paragraph } = Typography

const Home = () => {
  const { t } = useTranslation()

  return (
    <>
      <Title>{t('home.welcome')}</Title>
      <Paragraph>{t('home.welcome')}</Paragraph>
    </>
  )
}

export default Home
