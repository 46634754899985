import { useCallback, useEffect, useState } from 'react'
import Connex from '@vechain/connex'
import useInterval from '@hpm/useinterval'
import { Row, Col } from 'antd'
import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import Version from './Version'
import styled from 'styled-components'

const ConnectivityRow = styled(Row)`
  color: #fff;
`

const Link = styled.a`
  color: #fff;
`

const CONNEX_NETWORK_URL = process.env.REACT_APP_CONNEX_NETWORK_URL
const CONNEX_NETWORK_TYPE = process.env.REACT_APP_CONNEX_NETWORK_TYPE
const CONNEX_NETWORK_EXPLORER = process.env.REACT_APP_BLOCKCHAIN_EXPLORER

const connex = new Connex({
  node: CONNEX_NETWORK_URL,
  network: CONNEX_NETWORK_TYPE
})

const DELAY_WARNING_SECONDS = 30
const DELAY_ERROR_SECONDS = 60

export default function LayoutConnectivity (props) {
  const { t } = useTranslation()
  const [head, setHead] = useState()
  const [connecting, setConnecting] = useState(true)
  const [delay, setDelay] = useState(0)

  const waitForNextBlock = async function () {
    if (!connex) {
      return null
    }

    const head = await connex.thor.ticker().next()
    return head
  }

  useEffect(() => {
    let isUnmounted = false

    waitForNextBlock().then(head => {
      if (!head) {
        return
      }
      if (isUnmounted) {
        return
      }
      setHead(head)
    })
    return () => {
      isUnmounted = true
    }
  }, [head])

  useEffect(() => {
    if (connecting && head) {
      setConnecting(false)
    } else if (!connecting && !head) {
      setConnecting(true)
    }
  }, [head, connecting])

  useEffect(() => {
    if (!head) {
      return setDelay(0)
    }

    const now = +(new Date()) / 1000
    const delay = Math.round(now - head.timestamp)
    setDelay(delay < 0 ? 0 : delay)
  }, [head])

  const increaseDelay = useCallback(function () {
    setDelay(delay + 1)
  }, [delay])

  useInterval(increaseDelay, 1 * 1000)

  return (
    <ConnectivityRow>
      <Col span={2}>
        <StatusCircle delay={delay} loading={connecting} />
      </Col>
      <Col span={22}>
        <Link href={`${CONNEX_NETWORK_EXPLORER}/blocks/${head?.id}`} target='_blank' rel='noreferrer'>{t('footer.latestBlock')}{Number(head?.number || 0).toLocaleString() || 'Disonnected'}</Link> | {t('footer.lastKnownData', { delay })} | <Version />
      </Col>
    </ConnectivityRow>
  )
}

const StatusCircle = ({ delay, loading, ...restProps }) => {
  if (loading) {
    return <LoadingOutlined style={{ color: 'rgb(219, 219, 75)' }} {...restProps} />
  }

  if (delay > DELAY_ERROR_SECONDS) {
    return <CloseCircleOutlined style={{ color: 'rgb(219, 75, 75)' }} {...restProps} />
  }

  if (delay > DELAY_WARNING_SECONDS) {
    return <ClockCircleOutlined style={{ color: 'rgb(219, 219, 75)' }} {...restProps} />
  }

  return <CheckCircleOutlined style={{ color: 'rgb(75, 219, 75)' }} {...restProps} />
}
