import styled from 'styled-components'
import { Avatar } from 'antd'

const StyledImage = styled(Avatar)`
  margin-right: 8px;
`

const MoodImage = ({ src }) => <StyledImage src={src} shape='circle' size={42} />

export default MoodImage
