import { useEffect, useState, useCallback } from 'react'
import { useElection } from '@hpm/voteos-hooks'
import { message } from 'antd'
import Header from '../Header'
import Redeem from './Redeem'
import Confirm from './Confirm'
import { useLocation } from 'wouter'
import { useTranslation } from 'react-i18next'
import { getWalletForPrivateKey } from '../../../../hooks/useWallet'

export default function Votes ({ redeem, params }) {
  const { t } = useTranslation()
  const [, setLocation] = useLocation()

  const { loading: electionLoading, election, transferVote, setAddress, getVoteTokenCountOf, getVoteTokenId, voteTokenCount } = useElection()

  const [voteTokenId, setVoteTokenId] = useState('')
  const [loading, setLoading] = useState(true)

  const returnLink = `/${params.address}`

  const extractVoteTokenInformation = useCallback(async (privatekey) => {
    if (!privatekey) { return {} }
    const wallet = getWalletForPrivateKey(privatekey)
    const count = await getVoteTokenCountOf(wallet.address)
    const id = await getVoteTokenId(wallet.address)
    return { id, count }
  }, [getVoteTokenCountOf, getVoteTokenId])

  useEffect(() => {
    setAddress(params.address)
  }, [params.address, setAddress])

  useEffect(() => {
    if (params.privatekey) {
      extractVoteTokenInformation(params.privatekey)
        .then(({ id, count }) => {
          if (id) {
            setVoteTokenId(id)
          }
          if (!count) {
            setLocation(`/${params.address}`)
          }
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => setLoading(false))
    }
  }, [loading, electionLoading, params, extractVoteTokenInformation, setVoteTokenId, setLocation])

  const handleRedeem = useCallback(async (link) => {
    if (!link) {
      throw new Error(t('election.redeem.linkInput.errorEmptyLink'))
    }
    if (election?.state === 'started' && voteTokenCount > 0) {
      throw new Error(t('election.redeem.linkInput.electionActive'))
    }
    const privatekeyFromLink = link.split('/').pop()
    if (!privatekeyFromLink) {
      throw new Error(t('election.redeem.linkInput.errorInvalidLink'))
    }
    try {
      const { count } = await extractVoteTokenInformation(privatekeyFromLink)
      if (!count) {
        throw new Error(t('election.redeem.linkInput.errorAlreadyRedeemed'))
      }
    } catch (error) {
      console.error(error.message)
      throw new Error(t('election.redeem.linkInput.errorInvalidLink'))
    }
    setLocation(`/${params.address}/${privatekeyFromLink}/confirm`)
  }, [extractVoteTokenInformation, setLocation, params.address, t, election, voteTokenCount])

  const handleConfirm = useCallback(async () => {
    setLoading(true)
    const wallet = getWalletForPrivateKey(params.privatekey)
    try {
      await transferVote(wallet)
      message.success(t('election.redeem.confirmSuccess'))
      setLocation(returnLink)
    } catch (error) {
      setLoading(false)
      message.error(`${t('election.redeem.error')}${error.message}`)
    }
  }, [setLoading, transferVote, setLocation, params.privatekey, returnLink, t])

  const handleCancel = useCallback(() => {
    setLocation(returnLink)
  }, [setLocation, returnLink])

  return (
    <>
      <Header redeeming />
      {redeem
        ? <Redeem onRedeem={handleRedeem} onCancel={handleCancel} />
        : <Confirm loading={loading || electionLoading} tokenId={voteTokenId} onConfirm={handleConfirm} onCancel={handleCancel} privatekey={params.privatekey} address={params.address} />}
    </>
  )
}
