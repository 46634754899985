import Message from './Message'
import PropTypes from 'prop-types'

const ErrorMessage = ({ error = '', ...restProps }) => {
  if (!error) {
    return <></>
  }

  return <Message type='error' text={error} {...restProps} />
}

ErrorMessage.propTypes = {
  error: PropTypes.string
}

export default ErrorMessage
