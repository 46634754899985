import styled from 'styled-components'
import { Typography, Image } from 'antd'
import { useTranslation } from 'react-i18next'
import BallotImage from '../../Ballot.png'

const { Text } = Typography

const BallotNumber = styled.div`
  position: absolute;
  text-align: center;
  top: 42%;
  left: 25%;
  right: 25%;
  height: fit-content;
  max-width: 150px;
  margin: auto;
  font-size: 12px;
  color: ${({ theme }) => theme.primaryColor};
  background-color: #fff;
  border-radius: 50vh;
`

const CenteredImage = styled(Image)`
  max-height: 250px;
  height: 25vh;
  object-fit: contain;
`

const ImageOverlay = ({ tokenId }) => {
  const { t } = useTranslation()

  return (
    <>
      <CenteredImage src={BallotImage} preview={false} />
      <BallotNumber>
        <Text>{t('election.redeem.tokenNumber', { id: tokenId })}</Text>
      </BallotNumber>
    </>
  )
}

export default ImageOverlay
