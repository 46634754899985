import { useState, useEffect, useCallback, useContext, createContext } from 'react'
import { ethers } from '@vechain/ethers'
import { useRoute } from 'wouter'
import { useAuth } from './useAuth'

const WalletContext = createContext()

export const useWallet = () => {
  const context = useContext(WalletContext)

  if (context === undefined) {
    throw new Error('useWallet must be used within an WalletProvider')
  }

  return context
}

export const getWalletForPrivateKey = (privateKey) => privateKey ? new ethers.Wallet(privateKey) : ethers.Wallet.createRandom()

export const WalletProvider = ({ children }) => {
  const [wallet, setWallet] = useState()
  const { user, loading } = useAuth()
  const [, routeParams] = useRoute('/:electionAddress/:invitationWalletPrivatekey')

  const loadCustodianWallet = useCallback(async () => {
    if (loading) {
      return
    }

    if (!user?.privKey && !routeParams?.invitationWalletPrivatekey) {
      return setWallet(null)
    }

    const wallet = getWalletForPrivateKey(user?.privKey || routeParams.invitationWalletPrivatekey)
    setWallet(wallet)
  }, [user, routeParams?.invitationWalletPrivatekey, loading])

  useEffect(() => {
    loadCustodianWallet()
  }, [loadCustodianWallet])

  const value = {
    wallet
  }

  return <WalletContext.Provider value={value}>{children}</WalletContext.Provider>
}

export default useWallet
