import { useTranslation } from 'react-i18next'
import { Result } from 'antd'

const NotPublic = () => {
  const { t } = useTranslation()
  return (
    <Result
      status={404}
      subTitle={t('election.notPublic')}
    />
  )
}

export default NotPublic
