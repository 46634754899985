import { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { Typography, Row, Col, Button, Result, Divider } from 'antd'
import RedeemButton from '../RedeemButton'
import BallotCard from './BallotCard'
import LabeledValue from '../../../../common/LabeledValue'
import { useTranslation } from 'react-i18next'
import { useElection } from '@hpm/voteos-hooks'

const { Text, Title } = Typography

const CancelButton = styled(Button)`
  border-radius: 5px;
  border: solid 1px ${({ theme }) => theme.primaryColor} !important;
  color: ${({ theme }) => theme.primaryColor};
`

const CustomResult = styled(Result)`
  padding: 8px;
  margin-bottom: -16px;
`

const MarginBottomRow = styled(Row)`
  margin-bottom: 60px;
`

export default function Confirm ({ tokenId, onConfirm: handleConfirm, onCancel: handleCancel, loading }) {
  const { t } = useTranslation()
  const { getVoteWeight } = useElection()
  const [weight, setWeight] = useState()
  const [busy, setBusy] = useState(false)

  const loadWeight = useCallback(async (tokenId) => {
    const weight = await getVoteWeight(tokenId)
    setWeight(weight)
  }, [getVoteWeight])

  useEffect(() => {
    if (tokenId) {
      loadWeight(tokenId)
    }
  }, [tokenId, loadWeight])

  const checkConfirm = async () => {
    setBusy(true)
    await handleConfirm()
    setBusy(false)
  }

  return (
    <MarginBottomRow gutter={[16, 16]}>
      <Col span={24}>
        <Title level={3} style={{ whiteSpace: 'break-spaces' }}>{t('election.redeem.headline')}</Title>
      </Col>
      <Col span={24}>
        <Text style={{ whiteSpace: 'break-spaces' }}>{t('election.redeem.confirmHint')}</Text>
      </Col>
      <Col span={24}>
        <CustomResult
          icon={<BallotCard tokenId={tokenId} />}
        />
      </Col>
      <Col span={24}>
        <Title level={4}>{t('election.redeem.info')}</Title>
        <Divider />
        <LabeledValue title={t('election.redeem.weight')}>{weight}</LabeledValue>
        <Divider />
      </Col>
      <Col span={24}>
        <Row gutter={[0, 8]}>
          <RedeemButton
            loading={busy}
            disabled={loading}
            onClick={checkConfirm}
          />
          <CancelButton block size='large' onClick={handleCancel}>{t('cancel')}</CancelButton>
        </Row>
      </Col>
    </MarginBottomRow>
  )
}
