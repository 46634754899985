import styled, { useTheme } from 'styled-components'
import { Row, Col, Progress, Typography } from 'antd'
import MoodImage from '../MoodImage'
import DonutChart from './DonutChart'
import { t } from 'i18next'
import calculatePercentage from '../../../../../../lib/calculatePercentage'

const { Text } = Typography

const AnswerTitle = styled(Text)`
  font-size: 17px;
`

const AnswerRow = styled(Row)`
  padding-bottom: 16px;
`

const ProgressColumn = styled(Col)`
  padding-right: 18px;
`

const Result = ({ answers, allowAbstention, abstentionWeight, minVotes, maxVotes, totalVoteWeight, resultDisplayType, ...rest }) => {
  const theme = useTheme()
  const totalVotes = answers.reduce((total, answer) => total + answer.votes, abstentionWeight)
  const abstainPercentage = calculatePercentage(abstentionWeight, totalVotes)
  const areVoteCountsEqual = minVotes === maxVotes
  const isMultipleChoice = maxVotes > 1

  const infoText = (
    <Text type='secondary'>
      {areVoteCountsEqual ? t('election.poll.equalVotesDescription', { count: minVotes }) : t('election.poll.multipleChoiceInfo', { min: minVotes, max: maxVotes })}
    </Text>
  )

  if (resultDisplayType === 'pie') {
    const data = answers.map(answer => {
      return {
        type: answer.title,
        value: answer.votes,
        image: answer.image
      }
    })
    if (allowAbstention) {
      data.push({
        type: t('election.poll.abstain'),
        value: abstentionWeight
      })
    }
    return (
      <>
        <DonutChart data={data} />
        {infoText}
      </>
    )
  }
  return (
    <>
      {answers?.map((answer, index) => {
        const percentage = calculatePercentage(answer.votes, isMultipleChoice ? totalVoteWeight : totalVotes)
        return (
          <AnswerRow key={answer.id} align='middle' gutter={[0, 0]}>
            <Col span={22}>
              <Row align='middle' wrap={false}>
                {answer.image && <Col flex='50px'><MoodImage src={answer.image} /></Col>}
                <Col flex='auto'><AnswerTitle>{answer.title}</AnswerTitle></Col>
              </Row>
            </Col>
            <ProgressColumn span={24}>
              <Progress
                status='normal'
                percent={percentage}
                strokeColor={theme.primaryColor}
              />
            </ProgressColumn>
          </AnswerRow>
        )
      })}
      {allowAbstention && (
        <AnswerRow gutter={[0, 0]}>
          <Col span={24}>
            <AnswerTitle>{t('election.poll.abstain')}</AnswerTitle>
          </Col>
          <ProgressColumn span={24}>
            <Progress
              status='normal'
              percent={abstainPercentage}
              strokeColor={theme.primaryColor}
            />
          </ProgressColumn>
        </AnswerRow>
      )}
      {infoText}
    </>
  )
}

export default Result
