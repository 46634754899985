import styled from 'styled-components'
import { Layout, Row, Col } from 'antd'
import Footer from './Footer'

const { Content } = Layout

const StyledContent = styled(Content)`
  padding: 40px 5px 16px 5px;
  max-width: 1240px;
  min-height: calc(100vh - 246px);
  margin: auto;
  background: #fff;
`

const StyledLayout = styled(Layout)`
  background: #fff;
`

const Page = ({ children, ...restProps }) => {
  return (
    <StyledLayout {...restProps}>
      <Row>
        <Col span={22} offset={1}>
          <StyledContent>
            {children}
          </StyledContent>
        </Col>
      </Row>
      <Footer />
    </StyledLayout>
  )
}

export default Page
