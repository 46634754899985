import { useState, useEffect, useContext, createContext } from 'react'
import OpenLogin from '@toruslabs/openlogin'

import Theme from '../theme'
const torusOptions = {
  clientId: process.env.REACT_APP_TORUS_PROJECT_ID,
  network: process.env.REACT_APP_TORUS_NETWORK,
  whiteLabel: {
    theme: {
      primary: Theme.primaryColor
    }
  }
}
const AuthContext = createContext()

export const useAuth = () => {
  const context = useContext(AuthContext)

  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }

  return context
}

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState()
  const [signedIn, setSignedIn] = useState(false)
  const [loading, setLoading] = useState(true)
  const [openlogin, setOpenLogin] = useState(undefined)

  const autoLogin = async function () {
    setLoading(true)
    const openloginSdk = new OpenLogin(torusOptions)
    setOpenLogin(openloginSdk)

    await openloginSdk.init()

    if (openloginSdk.privKey) {
      window.localStorage.setItem('_lastUser', openloginSdk.privKey)
      setUser({ privKey: openloginSdk.privKey })
    } else if (window.localStorage.getItem('_lastUser')) {
      setUser({ privKey: window.localStorage.getItem('_lastUser') })
    } else {
      setUser()
    }
    setLoading(false)
  }

  async function handleLogin (email) {
    setLoading(true)
    try {
      await openlogin.init()
    } catch (error) {
      console.error('openlogin.init(): ', error)
    }
    try {
      await openlogin.login({
        fastLogin: true,
        loginProvider: 'email_passwordless',
        extraLoginOptions: {
          login_hint: email
        }
      })
      setUser({
        privKey: openlogin.privKey
      })
    } catch (error) {
      console.log(`Could not login user: ${error.message}`)
      setLoading(false)
    }
  }

  async function handleLogout () {
    setLoading(true)
    window.localStorage.removeItem('_lastUser')
    await setUser()
    await openlogin.logout()
    setLoading(false)
  }

  useEffect(() => autoLogin(), [])
  useEffect(() => setSignedIn(!!user?.privKey), [user])

  const value = {
    signedIn,
    user,
    login: handleLogin,
    loading,
    logout: handleLogout
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default useAuth
