import styled from 'styled-components'
import { Button } from 'antd'
import { FileDoneOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

const StyledButton = styled(Button)`
  background: ${({ theme }) => theme.primaryColor} !important;
  border-radius: 5px;
  ${({ disabled }) => disabled ? 'opacity: 0.4;' : ''}
  ${({ disabled }) => disabled ? 'color: #ffffffbb !important;' : ''}
`

const RedeemButton = (props) => {
  const { t } = useTranslation()
  return (
    <StyledButton
      block
      size='large'
      type='primary'
      icon={<FileDoneOutlined />}
      {...props}
    >
      {t('redeem')}
    </StyledButton>
  )
}
export default RedeemButton
