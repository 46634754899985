import { useState } from 'react'
import { Typography, Row, Col, Button, Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import RedeemButton from './RedeemButton'
import styled from 'styled-components'

const CancelButton = styled(Button)`
  border-radius: 5px;
  border: solid 1px ${({ theme }) => theme.primaryColor} !important;
  color: ${({ theme }) => theme.primaryColor};
`

const MarginBottomRow = styled(Row)`
  margin-bottom: 60px;
`

const { Paragraph, Title } = Typography

export default function Redeem ({ onRedeem: handleRedeem, onCancel: handleCancel }) {
  const { t } = useTranslation()
  const [link, setLink] = useState()
  const [error, setError] = useState()

  const handleChangeLink = (event) => {
    setError()
    setLink(event.target.value)
  }

  const checkRedeem = async () => {
    setError()
    try {
      await handleRedeem(link)
    } catch (error) {
      setError(error.message)
    }
  }

  return (
    <MarginBottomRow gutter={[16, 16]}>
      <Col span={24} />
      <Col span={24}>
        <Title level={4} style={{ whiteSpace: 'break-spaces' }}>{t('election.redeem.headline')}</Title>
      </Col>
      <Col span={24}>
        <Paragraph style={{ whiteSpace: 'break-spaces' }}>{t('election.redeem.hint')}</Paragraph>
      </Col>
      <Col span={24}>
        <Form
          onFinish={checkRedeem}
        >
          <Form.Item
            help={error}
            validateStatus={error ? 'error' : null}
          >
            <Input
              value={link}
              onChange={handleChangeLink}
              placeholder={t('election.redeem.linkInput.placeholder')}
              allowClear
            />
          </Form.Item>
        </Form>
      </Col>

      <Col span={24}>
        <Row gutter={[0, 8]}>
          <RedeemButton
            onClick={checkRedeem}
            disabled={!link}
          />
          <CancelButton block size='large' onClick={handleCancel}>{t('cancel')}</CancelButton>
        </Row>
      </Col>
    </MarginBottomRow>
  )
}
