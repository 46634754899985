import { Route, Switch } from 'wouter'
import { ElectionProvider } from '@hpm/voteos-hooks'
import { useWallet } from '../../hooks/useWallet'

import Layout from '../Layout'
import NotFound from './NotFound'
import Home from './Home'
import Election from './Election'
import Votes from './Election/Votes'
import Legal from './Legal'

export default function Pages () {
  return (
    <ElectionProvider walletHook={useWallet}>
      <Layout>
        <Switch>
          <Route path='/' component={Home} />
          <Route path='/legal' component={Legal} />
          <Route path='/:address' component={Election} />
          <Route path='/:address/redeem' component={(params) => <Votes redeem {...params} />} />
          <Route path='/:address/:privatekey/confirm' component={(params) => <Votes confirm {...params} />} />
          <Route path='/:address/:privatekey' component={Election} />
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </Layout>
    </ElectionProvider>
  )
}
