import styled, { useTheme } from 'styled-components'
import { Avatar, Space } from 'antd'

import { Pie } from '@ant-design/plots'

const Image = styled(Avatar)`
  margin-right: 8px;
`

const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const TooltipTitle = styled.div`
  font-size: 14px;
  max-width: 200px;
  padding: 8px 0;
`

const DonutChart = ({ data }) => {
  const theme = useTheme()
  const config = {
    data,
    legend: {
      layout: 'vertical',
      position: 'bottom',
      flipPage: false
    },
    label: {
      type: 'inner',
      offset: '-50%',
      autoRotate: false,
      style: {
        textAlign: 'center',
        fontSize: 14
      },
      content: ({ value, percent }) => {
        if (!value) return ''
        return `${value}\n(${Number(percent * 100).toFixed(1)}%)`
      }
    },
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        },
        content: ''
      }
    },
    tooltip: {
      customContent: (title, data) => {
        const { image } = (data && data[0] && data[0].data) || {}
        return (
          <Space direction='vertical'>
            <TooltipTitle>{title}</TooltipTitle>
            {image && <Image shape='circle' size={40} src={image} />}
          </Space>
        )
      }
    },
    theme: {
      colors10: theme.chartColors.slice(0, 10),
      colors20: theme.chartColors
    },
    colorField: 'type',
    angleField: 'value',
    radius: 1,
    innerRadius: 0.55,
    appendPadding: 10,
    autoFit: true,
    animation: false
  }
  return (
    <Centered><Pie {...config} /></Centered>
  )
}

export default DonutChart
