import { useTranslation } from 'react-i18next'
import { Result, Image } from 'antd'
import NotFoundImage from './Election/404.svg'

const NotFound = () => {
  const { t } = useTranslation()
  return (
    <Result
      icon={<Image src={NotFoundImage} preview={false} />}
      subTitle={t('notFound.message')}
    />
  )
}

export default NotFound
