import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Spin, Typography } from 'antd'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Description = styled(Typography.Text)`
  padding-top: 8px;
`

const Loading = ({ description, ...restProps }) => {
  return (
    <Container {...restProps}>
      <Spin size='large' />
      <Description>{description}</Description>
    </Container>
  )
}

Loading.propTypes = {
  description: PropTypes.string
}

export default Loading
