import { AppContextProvider } from './components/AppContextProvider'
import PageRouting from './components/Pages'
import { ThemeProvider } from 'styled-components'
import theme from './theme/colors'

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <AppContextProvider>
        <PageRouting />
      </AppContextProvider>
    </ThemeProvider>
  )
}

export default App
