import { AuthProvider } from '../hooks/useAuth'
import { WalletProvider } from '../hooks/useWallet'

const providers = [
  AuthProvider,
  WalletProvider
]

const combineComponents = (components) => {
  return components.reduce(
    (AccumulatedComponents, CurrentComponent) => {
      return ({ children }) => {
        return (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        )
      }
    },
    ({ children }) => <>{children}</>
  )
}

export const AppContextProvider = combineComponents(providers)
