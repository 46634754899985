import styled from 'styled-components'
import { Col } from 'antd'
import Lottie from 'react-lottie'
import loadingAnimation from './loadingAnimation.json'

const AnimationColumn = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const AnimationContainer = styled.div`
  max-width: 400px;
`

const Loading = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  return (
    <AnimationColumn span={24}>
      <AnimationContainer>
        <Lottie
          options={defaultOptions}
        />
      </AnimationContainer>
    </AnimationColumn>
  )
}

export default Loading
