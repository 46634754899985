import styled from 'styled-components'
import { Modal } from 'antd'
import { useTranslation } from 'react-i18next'

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-top-left-radius: ${({ theme }) => theme.borderRadius};
    border-top-right-radius: ${({ theme }) => theme.borderRadius};
  }
  .ant-modal-content {
    border-radius: ${({ theme }) => theme.borderRadius};
  }
  .ant-modal-footer {
    border-top: 0px;
    padding-right: 24px;
    padding-bottom: 24px;
  }
`

const RoundedModal = ({
  loading,
  disabled,
  onConfirm,
  visible,
  children,
  ...restProps
}) => {
  const { t } = useTranslation()
  return (
    <StyledModal
      onOk={onConfirm}
      okText={t('confirm')}
      okButtonProps={{ disabled, loading, size: 'large' }}
      cancelButtonProps={{ size: 'large' }}
      cancelText={t('cancel')}
      width={792}
      visible={visible}
      closable={false}
      {...restProps}
    >
      {children}
    </StyledModal>
  )
}

export default RoundedModal
